import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import LanguageForm from "@containers/language-translation/form";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import LanguageTranslationIntro from "@containers/language-translation/intro";
import LanguageKeywords from "@containers/language-translation/keywords";
import LanguageExpertise from "@containers/language-translation/expertise";
import LanguageTranslationTips from "@containers/language-translation/translation-tips";
import LanguageVarieties from "@containers/language-translation/varieties";
import LanguageTranslators from "@containers/language-translation/translators";
import LanguageVoiceovers from "@containers/language-translation/voiceovers";
import LanguageSoftware from "@containers/language-translation/software";
import LanguageGames from "@containers/language-translation/games";
import LanguageServices from "@containers/language-translation/services";
import LanguageDocuments from "@containers/language-translation/documents";
import LanguageFacts from "@containers/language-translation/facts";
import HowWeWork from "@containers/global/how-we-work";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";

const FrenchCanadianTranslationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="French Canadian Translation Services | Translation Company"
        description="Professional French Canadian Translation Services. Our Translation Company has over 500 qualified French Canadian Translators and Editors. Get a Free Quote!"
         />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["french-candian-translation-header"]} />
        <LanguageForm />
        <FeatureGrid data={content["french-canadian-translation-features"]} />
        <PartnerArea data={content["french-canadian-translation-logo"]} />
        <LanguageTranslationIntro
          data={content["french-candian-translation-intro"]}
        />
        <LanguageKeywords
          data={content["french-canadian-translation-keyword"]}
        />
        <LanguageTranslationIntro data={content["frenchcanadian-translation-services"]} />
        <LanguageExpertise
          data={content["french-canadian-translation-expertise"]}
        />
        <LanguageTranslationTips
          data={content["french-canadian-translation-tip"]}
        />
        <LanguageVarieties
          data={content["french-canadian-translation-varities"]}
        />
        <LanguageTranslators
          data={content["french-canadian-translation-translator"]}
        />
        <LanguageVoiceovers
          dataVoiceOver={content["french-canadian-translation-voiceover"]}
          dataWebsite={content["french-canadian-translation-website"]}
        />
        <LanguageSoftware
          data={content["french-canadian-translation-software"]}
        />
        <LanguageGames data={content["french-canadian-translation-games"]} />
        <LanguageServices
          intoEnglish={content["french-canadian-into-english"]}
          subtitlesData={content["french-canadian-translation-subtitle"]}
        />
        <LanguageDocuments
          documentData={content["french-canadian-translation-document"]}
          elearningData={content["french-canadian-translation-elearing"]}
        />
        <LanguageFacts data={content["french-canadian-translation-facts"]} />
        <HowWeWork data={content["howwework"]} />
        <CaseStudy data={caseStudiesData} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query frenCanadianTranslationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "french-canadian-translation" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

FrenchCanadianTranslationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default FrenchCanadianTranslationPage;
